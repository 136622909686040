(function ($) {


const Init = (() => {

  let website      = {},
      load         = {},
      resize       = {},
      scroll       = {},
      mod          = {},
      scrollOption = {},
      parallax     = {},
      drawer       = {},
      navi         = {},
      form         = {},
      timer        = {},
      tab          = {},
      wp           = {};

  website = {
    page          : document.body.dataset.page,
    url           : document.URL,
    winWidth      : window.innerWidth,
    winHeight     : window.innerHeight,
    scrollY       : window.pageYOffset,
    breakpoint    : { pc: 1200, tb: 992, sp: 768, ss: 576 },
    ua            : navigator.userAgent.toLowerCase(),
    isDesktop     : true,
    isFirst       : true,
    animationFrame: null,

    headTag       : $('.l-header'),

    act           : 'is-act',
    actOpen       : 'is-open',

    targetAnime : 'js-anime',
    actAnime    : 'is-anime',
    shiftPoint  : 0,
    shiftPc     : 5,
    shiftSp     : 10,
  };
  website.shiftPoint = website.breakpoint.tb;

  load = {
    functions: [],
    length   : 0,
  };

  resize = {
    functions: [],
    length   : 0,
    isRunning: false,
    animationFrame: null,
  };

  scroll = {
    functions: [],
    length   : 0,
    isRunning: false,
    animationFrame: null,
  };


  const WinLoad = {

    init() {
      window.addEventListener('load', function(e) {
        WinLoad.update();
      });

      console.log('!-- load init --!');
    },

    add(func) {
      load.functions.push(func);
      load.length = load.functions.length;
    },

    remove(func) {
      load.functions.splice(func, 1);
      load.length = load.functions.length;
    },

    update() {
      for ( let i = 0; i < load.length; i++ ) {
        let func = load.functions[i];
        func();
      }
    },

  }


  const WinResize = {

    init() {
      window.addEventListener('resize', function(e) {
        if ( !resize.isRunning ) {
          resize.isRunning = true;

          resize.animationFrame = window.requestAnimationFrame( WinResize.update );

        }
      });

      console.log('!-- resize init --!');
    },

    add(func) {
      resize.functions.push(func);
      resize.length = resize.functions.length;
    },

    remove(func) {
      resize.functions.splice(func, 1);
      resize.length = resize.functions.length;
    },

    update() {
      website.winWidth  = window.innerWidth;
      website.winHeight = window.innerHeight;
      website.ua        = navigator.userAgent.toLowerCase();

      for ( let i = 0; i < resize.length; i++ ) {
        let func = resize.functions[i];
        func();
      }

      resize.isRunning = false;
    },

  }


  const WinScroll = {

    init() {
      window.addEventListener('scroll', function(e) {
        if ( !scroll.isRunning ) {
          scroll.isRunning = true;

          scroll.animationFrame = window.requestAnimationFrame( WinScroll.update );

        }
      });

      console.log('!-- scroll init --!');
    },

    add(func) {
      scroll.functions.push(func);
      scroll.length = scroll.functions.length;
    },

    remove(func) {
      scroll.functions.splice(func, 1);
      scroll.length = scroll.functions.length;
    },

    update() {
      website.winHeight = window.innerHeight;
      website.scrollY = window.pageYOffset;

      for ( let i = 0; i < scroll.length; i++ ) {
        let func = scroll.functions[i];
        func();
      }

      scroll.isRunning = false;
    },

  }


  const Modules = {

    init() {
      mod = {
        targetSmooth    : 'js-scroll',
        smoothSpeed     : 600,

        targetAccordion : 'js-accordion',

        targetModal : 'js-modal',
        targetModalOpen : 'js-modalOpen',
        targetModalClose: 'js-modalClose',

        targetOfi       : '.js-ofi > *',

        targetMenu : 'js-menu',
        targetPagenavi : 'js-pagenavi',

        targetMegaWrap  : 'js-megaWrap',
        targetMegaTrg   : 'js-megaTrg',
        targetMegaCont  : 'js-megaCont',
        targetMegaClose : 'js-megaClose',

        targetDateField : 'js-datepicker',

      };

      let alignList = document.querySelectorAll('.' + mod.targetAlign);
      mod.targetAlignList = Array.prototype.slice.call(alignList, 0);

      this.iosCheck();
      this.deviceCheck();
      this.smoothScroll();
      this.accordionBtn();
      this.modalBtn();
      this.datepicker();
      this.megaMenu();

      objectFitImages(mod.targetOfi);
      Stickyfill.add('.js-sticky');
    },

    iosCheck() {
      if ( website.ua.indexOf('ipad') > -1 || website.ua.indexOf('iphone') > -1 || (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
        $('body').addClass('ios');
      } else {
        $('body').removeClass('ios');
      }
    },

    deviceCheck() {
      if (
        website.ua.indexOf('iphone') > -1 ||
        website.ua.indexOf('ipod') > -1 ||
        website.ua.indexOf('android') > -1 && website.ua.indexOf('mobile') > -1
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc tablet').addClass('sp');
      } else if (
        website.ua.indexOf('ipad') > -1 ||
        website.ua.indexOf('Android') > -1 ||
        (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document )
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc sp').addClass('tablet');
      } else {
        website.isDesktop = true;
        $('body').removeClass('tablet sp').addClass('pc');
      }
    },

    smoothScroll() {
      $('.' + mod.targetSmooth + ':not([href=""])').on('click', function() {
        let href     = $(this).attr("href"),
            target   = $( (href == "#") ? 'html' : href ),
            position;
        if($('.'+mod.targetMenu).length){
          shift    = ( (href != "#") ) ? $('.'+mod.targetMenu).outerHeight() : 0
        }else{
          shift    = 50;
        }
        position = target.offset().top - shift;
        $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
        return false;
      });
    },

    accordionBtn() {
      $('.' + mod.targetAccordion).on('click', function(){
        if ( $(this).hasClass(website.act) ) {
          $(this).removeClass(website.act).next().stop().slideUp('fast');
        } else {
          $(this).addClass(website.act).next().stop().slideDown();
        }
      });
    },

    modalBtn() {
      $('.'+mod.targetModalOpen).on('click',function(){
        $('body').addClass('is-modalOpen');
        let target = $(this).attr('href');
        $(target).fadeIn();
        return false;
      });
      $('.'+mod.targetModalClose).on('click',function(){
        $('body').removeClass('is-modalOpen');
        $(this).closest('.'+mod.targetModal).fadeOut();
        return false;
      });
    },

    megaMenu (){
      $('.'+mod.targetMegaTrg).on('click',function(){
        if(!$(this).hasClass('is-act')){
          $('.'+mod.targetMegaTrg).removeClass('is-act');
          $('.'+mod.targetMegaCont).removeClass('is-act').stop().slideUp(0);
        }
        $(this).toggleClass('is-act');
        $(this).siblings('.'+mod.targetMegaCont).toggleClass('is-act').stop().slideToggle();
      });


      $('.'+mod.targetMegaClose).on('click',function(){
        $(this).closest('.'+mod.targetMegaCont).removeClass('is-act').stop().slideUp().siblings('.'+mod.targetMegaTrg).removeClass('.is-act');
      });

      $('.'+mod.targetMegaWrap).find('a:not(.'+mod.targetMegaTrg+')').on('click',function(){
        $('.'+mod.targetMegaTrg).removeClass('is-act');
        $('.'+mod.targetMegaCont).removeClass('is-act').stop().slideUp(0);
      });
    },

    datepicker (){
      if($('.'+mod.targetDateField).length) {
        $('.'+mod.targetDateField).each(function(){
          $(this).prop('readonly', 'readonly');
        });
        minDate = new Date();
        $.datetimepicker.setLocale('ja');
        $('.'+mod.targetDateField).datetimepicker({
          format:'Y-m-d',
          minDate : minDate.setDate(minDate.getDate() + 1),
          timepicker:false,
          scrollMonth : false,
          scrollInput : false,
        });
      }
    }
  }

  const Animation = {

    init() {
    },

    check() {
      $('.' + website.targetAnime + ':not(.' + website.actAnime + ')').each(function(){
        let self = $(this),
            targetPosition = self.offset().top,
            triggerPosition,
            shift;

        if( website.winWidth < website.shiftPoint ) {
          shift = website.winHeight / website.shiftSp;
        } else {
          shift = website.winHeight / website.shiftPc;
        }
        triggerPosition = targetPosition - website.winHeight + shift;

        if( website.scrollY > triggerPosition ){
          self.addClass(website.actAnime);
        }
      });
    },

  }


  const Tab =  {
    init() {
      tab = {
        targetWrap    : '.js-tab',
        targetTrg     : '.js-tabTrg',
        targetConts   : '.js-tabConts',
        targetTgl     : '.js-tabTgl',
        mode          : 'tab',
      }

      this.modeCheck();
      this.check();
      this.pulldown();

      if( website.breakpoint.sp > website.winWidth ){
        tab.mode = 'pulldown';
      }

      let hash = window.location.hash;
      $(tab.targetWrap).each(function(){
        $(this).find(tab.targetConts).children().hide();
        if(hash.substr(1,4) == '#tab_'){
          if($(this).find(tab.targetTrg).find('a[href="'+hash+'"]').length && $(this).find(tab.targetConts).find(hash).length){
            $(this).find(tab.targetTrg).find('a[href="'+hash+'"]').click();
          }
        }else{
          $(this).find(tab.targetTrg).children().eq(0).find('a').click();
        }
      });
    },

    check() {
      $(tab.targetTrg).find('a').on('click',function(){
        let tabWrap = $(this).closest(tab.targetWrap),
            tabTrg  = $(this).closest(tab.targetTrg);
        let tabTgl = tabTrg.parent(tab.targetTgl);
        let tabConts  = tabTgl.siblings(tab.targetConts);
        tabTrg.find('a').removeClass('is-act');
        tabConts.children().removeClass('is-act').hide();
        $(this).addClass('is-act');
        tabTgl.children('label').removeClass('is-act').html($(this).html());
        tabConts.find($(this).attr('href')).addClass('is-act').fadeIn(400);

        if( tab.mode == 'pulldown' ){
          $(tabTrg).slideUp(400);
        }
        return false;
      });
    },

    pulldown(){
      $(tab.targetTgl).children('label').on('click',function(){
        $(this).toggleClass('is-act').parent(tab.targetTgl).children(tab.targetTrg).slideToggle(0);
      });
    },

    modeCheck(){
      if( website.breakpoint.sp > website.winWidth ){
        tab.mode = 'pulldown';
        $(tab.targetTgl).children('label').removeClass('is-act').show();
        $(tab.targetTrg).css({
          'display': 'none',
          'height': ''
        });
      }else{
        tab.mode = 'tab';
        $(tab.targetTgl).children('label').removeClass('is-act').hide();
        $(tab.targetTrg).css({
          'display': '',
          'height': ''
        });
      }
    }
  }

  // const Timer = {
  //   init() {
  //     timer = {
  //       targetClass : '.js-timer',
  //     };
  //     this.check();
  //   },
  //   check() {
  //     if($(timer.targetClass).length){
  //       $(timer.targetClass).each(function(){
  //         let startTime   = new Date($(this).data('start')),
  //             endTime     = new Date($(this).data('end')),
  //             currentTime = new Date();
  //         if(startTime <= currentTime && currentTime <= endTime){
  //           $(this).siblings(timer.targetClass).hide();
  //           return false;
  //         }
  //       });
  //     }
  //   }
  // }



  // const PageScroll = {

  //   init() {
  //     scrollOption = {
  //       target    : document.getElementById('js-contbox'),
  //       translateY: 0,
  //       contHeight: 0,
  //       velocity  : 0.08,

  //       rootMargin : '0px',
  //       threshold  : 0.1,
  //     };

  //     this.observer();
  //   },

  //   set() {
  //     scrollOption.contHeight    = scrollOption.target.getBoundingClientRect().height - 1;
  //     document.body.style.height = Math.floor(scrollOption.contHeight) + 'px';
  //   },

  //   smooth() {
  //     this.set();

  //     let diff = website.scrollY - scrollOption.translateY;

  //     if ( diff !== 0 ) {
  //       if ( Math.abs(diff) > 1 ) {
  //         scrollOption.translateY += diff * scrollOption.velocity;
  //       } else {
  //         scrollOption.translateY = website.scrollY;
  //       }
  //       scrollOption.target.style.transform = 'translate3d(0, -' + scrollOption.translateY + 'px, 0)';
  //     }
  //   },

  //   observer() {
  //     let targets = Array.from( document.querySelectorAll('.' + website.targetAnime) ),
  //         options = {
  //           rootMargin : scrollOption.rootMargin,
  //           threshold  : scrollOption.threshold,
  //         };
  //     let observer = new IntersectionObserver(this.observerCallback, options);

  //     targets.forEach((target) => {
  //       if ( target.dataset.delay ) {
  //         target.style.transitionDelay = target.dataset.delay + 'ms';
  //       }

  //       observer.observe(target);
  //     });
  //   },

  //   observerCallback(entries, object) {
  //     entries.forEach(function(entry, i) {
  //       if (!entry.isIntersecting) return;
  //       entry.target.classList.add(website.actAnime);
  //       object.unobserve(entry.target);
  //     });
  //   },
  // }


  // const Parallax = {

  //   init() {
  //     parallax = {
  //       nodeList   : document.querySelectorAll('.js-parallax'),
  //       gap        : 0.08,

  //       decoList   : [],
  //     };

  //     let targets = Array.prototype.slice.call(parallax.nodeList, 0);
  //     targets.forEach((target) => {
  //       if ( target.dataset.para ) {
  //         let arr = target.dataset.para.split(',');
  //         parallax.decoList.push( [target, arr] );
  //       }
  //     });
  //   },

  //   decoImg() {
  //     parallax.decoList.forEach((target) => {
  //       let targetRect = target[0].getBoundingClientRect(),
  //           parentNode = document.querySelector(target[1][0]),
  //           parentRect = parentNode.getBoundingClientRect(),
  //           diff       = targetRect.top - parentRect.top,
  //           gap        = (target[1][3]) ? Number(target[1][3]): parallax.gap,
  //           translateX,
  //           translateY;

  //       if ( (targetRect.top - website.winHeight) <= 0 ) {
  //         if ( target[1][1] == 'X' ) {
  //           translateX = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(' + translateX + 'px, 0px, 0px)';
  //         } else {
  //           translateY = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(0, ' + translateY + 'px, 0)';
  //         }

  //       }
  //     });
  //   },

  // }


  const DrawerMenu = {

    init() {
      drawer = {
        panel     : 'js-so_panel',
        menu      : 'js-so_menu',
        duration  : 10,
        toggleList: '#js-so_toggle',
        closeList : '#js-so_panel, #js-so_menu .js-scroll',
      };

      this.menu();
    },

    menu() {
      let slideoutInst = new Slideout({
        'panel': document.getElementById(drawer.panel),
        'menu': document.getElementById(drawer.menu),
        'duration': drawer.duration,
      });

      let nodeList = document.querySelectorAll(drawer.toggleList);
      let node = Array.prototype.slice.call(nodeList,0);
      node.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.toggle();
        });
      });

      let nodeListClose = document.querySelectorAll(drawer.closeList);
      let nodeClose = Array.prototype.slice.call(nodeListClose,0);
      nodeClose.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.close();
        });
      });
    },

  }


  const Wordpress = {

    init() {
      wp = {
        editorTarget: 'js-editor',
        tableClass  : 'c-tbl_responsive',
        tableInnerClass  : 'c-tbl_responsive__inner',
      }

      this.wpEditor();
    },

    wpEditor() {
      if ( $('.' + wp.editorTarget).length ) {
        $( '.' + wp.editorTarget + ' table' ).each(function() {
          $(this).wrap('<div class="' + wp.tableClass + '"><div class="' + wp.tableInnerClass + '"></div></div>');
        });
      }
    },

  }

  // const Form = {
  //   init(){
  //     form ={
  //       targetClass  : "mw_wp_form",
  //       confirmClass : "mw_wp_form_confirm",
  //       errorClass   : "error",
  //     }
  //   },
  //   check(){
  //     if($('.'+form.targetClass).length){
  //       let shift    = $('.'+mod.targetMenu).outerHeight(),
  //           position = $('.'+form.targetClass).offset().top - shift;
  //       if($('.'+form.targetClass).hasClass(form.confirmClass) || $('.'+form.errorClass).length){
  //         $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
  //         return false;
  //       }
  //     }
  //   },
  // }


  const Slick = {

    init() {
    },

    topKey() {
    },

  }

  const Top = {

    init() {
      Tab.init();
      if(website.winWidth >= website.breakpoint.sp){
        $('.js-topKey').css({
          'padding-top': website.headTag.outerHeight()+'px',
          'margin-top': -1*website.headTag.outerHeight()+'px',
        });
      }
    },
    animate() {
      $('.js-topKey').addClass('is-anime');
    },
    update() {
      Tab.modeCheck();

      if(website.winWidth >= website.breakpoint.sp){
        $('.js-topKey').css({
          'padding-top': website.headTag.outerHeight()+'px',
          'margin-top': -1*website.headTag.outerHeight()+'px',
        });
      }else{
        $('.js-topKey').css({
          'padding-top': '',
          'margin-top': '',
        });

      }
    },

  }

  const BasicPage = {

    init() {
    },

    update() {
    },

  }


  const App = {

    init() {
      this.set();
      // this.update();
    },

    set() {
      Modules.init();
      DrawerMenu.init();
      Wordpress.init();

      if ( website.page == 'top' ) {
        Top.init();
        WinLoad.add( Top.animate );
        WinLoad.add( Top.update );
        WinResize.add( Top.update );
      }


      WinLoad.add( Animation.check );

      WinResize.add( Modules.iosCheck );
      WinResize.add( Modules.deviceCheck );

      WinScroll.add( Animation.check );


      WinLoad.init();
      WinResize.init();
      WinScroll.init();
    },

    // update() {
    //   website.animationFrame = window.requestAnimationFrame( App.update );
    //   website.winHeight      = window.innerHeight;
    //   website.scrollY        = window.pageYOffset;

    //   if ( website.isDesktop ) {
    //     PageScroll.smooth();

    //     if ( website.page == 'top' ) {
    //       Parallax.decoImg();
    //     }
    //   }
    //   // else {
    //   //   PageScroll.set();
    //   // }
    // },

  }


  App.init();

})();


})(jQuery);
